import archive from '../../public/images/icons/archive.svg';
import arrowDown from '../../public/images/icons/arrow-down.svg';
import billing from '../../public/images/icons/billing.svg';
import refresh from '../../public/images/icons/refresh.svg';
import code from '../../public/images/icons/code.svg';
import cog from '../../public/images/icons/cog.svg';
import copy from '../../public/images/icons/copy.svg';
import dashboard from '../../public/images/icons/dashboard.svg';
import down from '../../public/images/icons/down.svg';
import download from '../../public/images/icons/download.svg';
import edit from '../../public/images/icons/edit.svg';
import exclamation from '../../public/images/icons/exclamation.svg';
import eye from '../../public/images/icons/eye.svg';
import fingerprint from '../../public/images/icons/fingerprint.svg';
import forms from '../../public/images/icons/forms.svg';
import globe from '../../public/images/icons/globe.svg';
import integrations from '../../public/images/icons/integrations.svg';
import left from '../../public/images/icons/left.svg';
import logo from '../../public/images/icons/logo.svg';
import serpwall from '../../public/images/icons/serpwall.svg';
import tofu from '../../public/images/icons/tofu.svg';
import tofuWithText from '../../public/images/icons/tofu-with-text.svg';
import menu from '../../public/images/icons/menu.svg';
import plusCircle from '../../public/images/icons/plus-circle.svg';
import plus from '../../public/images/icons/plus.svg';
import questionCircle from '../../public/images/icons/question-circle.svg';
import right from '../../public/images/icons/right.svg';
import shielde from '../../public/images/icons/shielde.svg';
import sparkles from '../../public/images/icons/sparkles.svg';
import team from '../../public/images/icons/team.svg';
import unplug from '../../public/images/icons/unplug.svg';
import up from '../../public/images/icons/up.svg';
import user from '../../public/images/icons/user.svg';
import x from '../../public/images/icons/x.svg';
import xcircle from '../../public/images/icons/xcircle.svg';

export type OneOfIcons =
  | 'logo'
  | 'serpwall'
  | 'tofu'
  | 'tofu-text'
  | 'shielde'
  | 'refresh'
  | 'sparkles'
  | 'fingerprint'
  | 'globe'
  | 'download'
  | 'eye'
  | 'left'
  | 'right'
  | 'up'
  | 'down'
  | 'copy'
  | 'code'
  | 'cog'
  | 'edit'
  | 'user'
  | 'billing'
  | 'integrations'
  | 'menu'
  | 'x'
  | 'xcircle'
  | 'team'
  | 'exclamation'
  | 'arrow-down'
  | 'plus-circle'
  | 'question-circle'
  | 'plus'
  | 'unplug'
  | 'forms'
  | 'archive'
  | 'dashboard';

const allIcons: Record<OneOfIcons, React.FC<React.SVGProps<SVGSVGElement>>> = {
  logo,
  serpwall,
  sparkles,
  shielde,
  refresh,
  fingerprint,
  globe,
  eye,
  left,
  right,
  up,
  down,
  download,
  copy,
  code,
  cog,
  edit,
  user,
  billing,
  integrations,
  menu,
  x,
  xcircle,
  team,
  exclamation,
  unplug,
  dashboard,
  forms,
  plus,
  archive,
  'plus-circle': plusCircle,
  'question-circle': questionCircle,
  'arrow-down': arrowDown,
  tofu,
  'tofu-text': tofuWithText,
};

type Props = {
  type: OneOfIcons;
  className?: string;
};

const Icon: React.FC<Props> = ({ type, className }: Props) => {
  const IconComponent = allIcons[type];

  return <IconComponent className={className} />;
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
